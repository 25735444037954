import { Injectable, NgZone } from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService} from './api.service';
import { Platform } from '@ionic/angular';



import { toastController } from '@ionic/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    /*
        The platform string this app is running on: ('mobile', 'tablet')
     */
    private platform: string;

    /*
        Active app route (when a tab is selected it redirects to a route that is configured in home.router.module)
    */
    public activeRoute: string;

    /*
        Context
     */
    private parentContext: any;
    private currentCtxName: string;
    private currentCtxId: number;
    private currentCtxIdType: string;
    public context = [];
    public contextChildren = [];

    /*
        Keys
     */
    //public GMAP_API_KEY     = 'AIzaSyDAvZHrm3XrM44MrAT9N5R-1Yks-bQ00HQ';
    public GMAP_API_KEY     = 'AIzaSyDAvZHrm3XrM44MrAT9N5R-1Yks-bQ00HQ';
    

    /*
        Alarms
     */
    public alarms = null;
    public alarmDetails = null;

    /*
        Live Data
    */
   public containers = null;
    public containerDetails = null;

    /*
        Component Messaging
     */
    public reloadSubject = new Subject <{key: string, value: any}>();

    /*
        Permissions
     */
    public SECTION_CODE_DATAMANIPULATION = 'dmp';
    public SECTION_CODE_DASHBOARD      = 'das';
    public SECTION_CODE_ALARMS         = 'alr';
    public SECTION_CODE_ALARMS_DIAGNOSTIC = 'dgn';
    public SECTION_CODE_MAP            = 'map';
    public SECTION_CODE_DOCUMENT       = 'doc';
    public SECTION_CODE_DATAEXPLORER   = 'datex';
    public SECTION_CODE_STATIONS       = 'tree';
    public SECTION_CODE_REPORTS        = 'rpt';
    public SECTION_CODE_USERS          = 'usr';
    public SECTION_CODE_SENSOR         = 'snr';
    public SECTION_CODE_CONTROLLER     = 'ctr';
    public SECTION_CODE_PARKING_MAP    = 'pkm';
    public SECTION_CODE_ORGANIZATION   = 'ofc';
    public SECTION_CODE_CLIENT         = 'cln';
    public SECTION_CODE_FACILITY       = 'fcl';
    public SECTION_CODE_THING          = 'fcl'; // TODO: REFACTOR TO NEW CODE THING
    public SECTION_CODE_LIVE_DATA      = 'ldm';
    public SECTION_CODE_SUBSCRIPTIONS  = 'sub';
    public SECTION_CODE_SERVICE_TASK   = 'srt';

    public PERMISSION_CREATE = 'create';
    public PERMISSION_DELETE = 'delete';
    public PERMISSION_READ   = 'read';
    public PERMISSION_UPDATE = 'update';

    public PANEL_TYPE_FLOORPLAN = 3404;
    public PANEL_TYPE_TABLE   = 3405;
    public PANEL_TYPE_TABLE_LISTING   = 3451;
    public PANEL_TYPE_MAP     = 3407;
    public PANEL_TYPE_DIGITAL = 3411;
    public PANEL_TYPE_CHART   = 3412;
    public PANEL_TYPE_GAUGE   = 3413;
    public PANEL_TYPE_WINDROSE = 3422;
    public PANEL_TYPE_HEATMAP = 3423;
    public PANEL_TYPE_TABLE_CUSTOM = 3452;
    public PANEL_TYPE_HISTORICAL_TABLE = 3415;

    public DEVICE_TYPE_VIRTUAL = 2100;
    public DEVICE_TYPE_GATEWAY = 2101;
    public DEVICE_TYPE_AIRMET  = 2102;
    public DEVICE_TYPE_GATEWAYBOARD = 2103;
    public DEVICE_TYPE_CELLULAR  = 2104;
    public DEVICE_TYPE_PLC = 2111;
    public DEVICE_TYPE_ZBCOORDINATOR = 2121;
    public DEVICE_TYPE_ZBROUTER = 2122;
    public DEVICE_TYPE_ZBENDPOINT = 2123;
    public DEVICE_TYPE_OTHER = 2131;
    public DEVICE_TYPE_LORAWAN_NODE = 2142;
    public DEVICE_TYPE_LORAWAN_GATEWAY = 2141;

    public DEVICE_MODEL_DT9X = 32;
    public DEVICE_MODEL_DT8X = 23;

    public ALARM_STATE_DEFAULT = 11102;
    public ALARM_LEVEL_DEFAULT = 11302;

    public TYPE_SENSOR          = '592';
    public TYPE_DEVICE          = '591';
    public TYPE_THING           = '599';
    public TYPE_FACILITY        = '504';
    public TYPE_CLIENT          = '502';
    public TYPE_ORGANIZATION    = '501';

    public TYPE_THING_LABEL          = 'THING';
    public TYPE_FACILITY_LABEL        = 'FACILITY';
    public TYPE_CLIENT_LABEL          = 'CLIENT';
    public TYPE_ORGANIZATION_LABEL    = 'ORGANIZATION';

    public CONTACTTYPEID_CALLSMS = 301;
    public CONTACTTYPEID_SMS = 302;
    public CONTACTTYPEID_EMAIL = 304;

    public DIGITAL_TYPE_BINARY_OPTION_1 = 1;
    public DIGITAL_TYPE_BINARY_OPTION_2 = 2;

    private LIMITPIXELSMALLSCREEN = 1020;

    public ID_DIAGNOSTIC_LEVEL_TYPE = 11306;

    public timeRangesNoLastValues = [
        /*
        {
            Label : 'Last hour',
            ID : 'lastHour'
        },
        {
            Label : 'Last 3 hours',
            ID : 'last3hours'
        },
        */
        {
            Label : 'Last 24 hours',
            ID : 'last24hours'
        },
        {
            Label : 'Last week',
            ID : 'lastWeek'
        },
        {
            Label : 'Last 2 weeks',
            ID : 'last2weeks'
        },
        {
            Label : 'Last month',
            ID : 'lastMonth'
        },
        {
            Label : 'Last 3 months',
            ID : 'last3months'
        },
        {
            Label : 'Last 6 months',
            ID : 'last6months'
        },
        {
            Label : 'Last 12 months',
            ID : 'last12months'
        }
    ];
    public timeRanges = [
        {
            Label : 'Last 50 values',
            ID : 'last50values'
        },
        {
            Label : 'Last 100 values',
            ID : 'last100values'
        },
        {
            Label : 'Last hour',
            ID : 'lastHour'
        },
        {
            Label : 'Last 3 hours',
            ID : 'last3hours'
        },
        {
            Label : 'Last 6 hours',
            ID : 'last6hours'
        },
        {
            Label : 'Last 12 hours',
            ID : 'last12hours'
        },
        {
            Label : 'Last 24 hours',
            ID : 'last24hours'
        },
        {
            Label : 'Last week',
            ID : 'lastWeek'
        },
        {
            Label : 'Last 2 weeks',
            ID : 'last2weeks'
        },
        {
            Label : 'Last month',
            ID : 'lastMonth'
        },
        {
            Label : 'Last 3 months',
            ID : 'last3months'
        },
        {
            Label : 'Last 6 months',
            ID : 'last6months'
        },
        {
            Label : 'Last 12 months',
            ID : 'last12months'
        }
    ];

    public timeRangesWindRose = [
        {
            Label : 'Last 1 value',
            ID : 'last1values'
        },
        {
            Label : 'Last 5 values',
            ID : 'last5values'
        },
        ...this.timeRanges
    ];

    public groupBysNoAllValues = [
        {
            Label : 'Hourly',
            ID : 'hour'
        },
        {
            Label : 'Daily',
            ID : 'day'
        },
        {
            Label : 'Weekly',
            ID : 'week'
        },
        {
            Label : 'Monthly',
            ID : 'month'
        },
        {
            Label : 'Yearly',
            ID : 'year'
        }
    ];
    public groupBys = [
        /*
      {
          Label : '---',
          ID : null
      },
      */
        {
            Label : 'All Values',
            ID : 'allValues'
        },
        {
            Label : 'Hourly',
            ID : 'hour'
        },
        {
            Label : 'Daily',
            ID : 'day'
        },
        {
            Label : 'Weekly',
            ID : 'week'
        },
        {
            Label : 'Monthly',
            ID : 'month'
        },
        {
            Label : 'Yearly',
            ID : 'year'
        }
    ];
    public andGroupBys = {
        hour : [
            {
                Label : 'Weekly',
                ID : 'week'
            },
            {
                Label : 'Monthly',
                ID : 'month'
            }
        ],
        day: [
            {
                Label : 'Weekly',
                ID : 'week'
            },
            {
                Label : 'Monthly',
                ID : 'month'
            }
        ],
        week: [
            {
                Label : 'Yearly',
                ID : 'year'
            }
        ],
        month: [
            {
                Label : 'Yearly',
                ID : 'year'
            }
        ]
    };
    public operations = [
        /*
        {
            Label : '---',
            ID : null
        },
        */
        {
            Label : 'Average',
            ID : 'avg'
        },
        {
            Label : 'Rounded Average',
            ID : 'roundedavg'
        },
        {
            Label : 'Sum',
            ID : 'sum'
        },
        /*
        {
            Label : 'Sum/60',
            ID : 'sumdiv60'
        },
        */
        {
            Label : 'Minimum',
            ID : 'min'
        },
        {
            Label : 'Maximum',
            ID : 'max'
        },
        {
            Label : 'Sum of hourly averages',
            ID : 'sumofhourlyavg'
        }];

    public presetColors = ['#AAEEEE', '#FF0066', '#F7A35C', '#90EE7E', '#7798BF', '#DF5353'];

    public defaultGreen  = '#66FF99';
    public defaultYellow = '#FFFF99';
    public defaultRed    = '#FF3300';

    public permissions = null;


    private tokenTimer: any;

    private platformObj: Platform;
    public platformWidth: any;
    public platformHeight: any;

    //constructor() { }

    constructor(platform: Platform, private ngZone: NgZone) {
        platform.ready().then(() => {
          console.log('Width: ' + platform.width());
          console.log('Height: ' + platform.height());

          this.platformObj = platform;
          this.platformWidth =  platform.width();
          this.platformHeight =  platform.height();

          this.platformObj.resize.subscribe(async () => {
                this.platformWidth =  platform.width();
                this.platformHeight =  platform.height();
                this.ngZone.run(() => {}); // force update in components when detected dimension changes
            });
        });
    }



    checkTokenIsExpired(router: Router, apiService: ApiService) {
        const now = new Date();
        const expirationDate = localStorage.getItem('expiration');
        if(expirationDate){
            const expiresIn = new Date(expirationDate).getTime() - now.getTime();
            if(expiresIn > 0){
                this.setAuthTimer(expiresIn/1000, router, apiService);
                return false;
            }
        }
        return true;
    }

    setAuthTimer(duration: number, router: Router, apiService: ApiService) {
        console.log("Setting timer: " + duration);
        const now = new Date();
        const expirationDate = new Date(now.getTime() + duration * 1000);
        localStorage.setItem('expiration', expirationDate.toISOString())
        this.tokenTimer = setTimeout(() => {
            this.logout(router, apiService)
        }, duration * 1000);
    }  


    setPermissions(perms: []) {
        this.permissions = perms;
    }

    checkPermission(sectionCode: string, permission: string) {
        const sc = this.permissions.filter((perm) => perm.code === sectionCode)[0];
        if (sc) {
            return sc[permission];
        }
        return false;
    }

    // deprecated - obsolete / must refactor to checkPermission.
    permissionRead(sectionCode: string) {
        const sc = this.permissions.filter((perm) => perm.code === sectionCode)[0];
        if (sc) {
            return sc.read;
        }
        return false;
    }

    logout(router: Router, apiService: ApiService) {
        apiService.loading = false;
        localStorage.setItem('token', '');
        localStorage.setItem('permissions', '');
        clearTimeout(this.tokenTimer);
        apiService.stop();
        router.navigateByUrl('login');
    }

    getPlatform() {
        return Capacitor.getPlatform();
    }

    platformIsMobile() {
        // return true; // TODO XXX: TESTING ONLY
        return Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android';
    }

    isScreenSmall() {
        return this.platformWidth < this.LIMITPIXELSMALLSCREEN;
    }

    platformIsTabletOrDesktop() {
        // return false; // TODO XXX: TESTING ONLY
        //return this.platform === 'tablet' || this.platform === 'desktop';

        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
            if (this.platformWidth >= this.LIMITPIXELSMALLSCREEN) {
              //Running on tablet
              //console.log('Running on tablet');
              return true;
            } else {
              //Running on phone
              //console.log('Running on phone');
              return false;
            }
        } else {
            if (this.platformWidth < this.LIMITPIXELSMALLSCREEN) {
                 //Running on browser mobile or browser desktop with small size 
                 return false;
            }
            //Running on desktop
            //console.log('Running on desktop');
            return true;
        }
    }

    platformIsTablet() {
        // return false; // TODO XXX: TESTING ONLY
        return this.platform === 'tablet';
    }

    setPlatform(p: string) {
        this.platform = p;
    }

    setCurrentCtxName(name: string){
        this.currentCtxName = name;
    }
    getCurrentCtxName(){
        return this.currentCtxName;
    }
    setCurrentCtxId(id: number) {
        this.currentCtxId = id;
    }
    getCurrentCtxId() {
        return this.currentCtxId;
    }
    getParentContext() {
        return this.parentContext;
    }
    setCurrentCtxIdType(idType: string) {
        this.currentCtxIdType = idType;
    }

    getCurrentCtxIdType() {
        return this.currentCtxIdType;
    }

    initContext(response: any) {
        this.contextChildren.push(response.data.currentContext);
    }
    clearContext() {
        this.context.length = 0;
        this.contextChildren.length = 0;
        console.log('Context Cleared!');
    }
    updateContext(response: any) {
        this.contextChildren.length = 0;
        if (response.data.currentContext.children.length > 0) {
            response.data.currentContext.children.forEach(c => {
                this.contextChildren.push({
                    id: c.id,
                    name: c.name,
                    typeId: c.typeId
                });
            });
        } else {
            this.contextChildren.push(response.data.currentContext);
        }
    }

    test(val) {
        this.contextChildren.push(val);
    }

    setParentContext() {
        const focusedContext = this.context[this.context.length - 1];
        if(focusedContext.typeId !== this.TYPE_THING) {
            this.parentContext = focusedContext;
        }
    }

    chooseContext(level: any, index: any, moreContext: () => void) {
        if (level.stack === true) {
            this.context.splice(index + 1, this.context.length);
        } else {
            level.stack = true;
            this.context.push(level);
        }
       
        this.setCurrentCtxName(level.name);
        this.setCurrentCtxId(level.id);
        this.setCurrentCtxIdType(level.typeId);
        this.setParentContext();

        this.contextChildren.length = 0;
        if (level.children) {
            level.children.forEach(child => {
                this.contextChildren.push({
                    id: child.id,
                    name: child.name,
                    typeId: child.typeId
                });
            });
        } else {
            // if (level.type !== 'Thing') {
            // if (level.type !== '599') {
                if (moreContext) {
                    moreContext();
                }
           //  }
        }
    }

    navigateTo(fid: number, eid: number, apiService: ApiService) {
        // Find if 'id' exists at the level below
        let ctxObj = null;
        let ctxIndex = null;
        if (fid && eid) {
            ctxObj = this.contextChildren.find(c => c.id === eid && c.typeId === '599');
            ctxIndex = this.contextChildren.findIndex(c => c.id === eid && c.typeId === '599');
            if (ctxObj && (ctxIndex > -1)) {
                this.chooseContext(ctxObj, ctxIndex, () => {
                    this.reloadSubject.next({key: 'dashboard', value: null});
                });
            } else {
                // Search for facility
                ctxObj = this.contextChildren.find(c => c.id === fid && c.typeId === '504');
                ctxIndex = this.contextChildren.findIndex(c => c.id === fid && c.typeId === '504');
                if (ctxObj && (ctxIndex > -1)) {
                    this.chooseContext(ctxObj, ctxIndex, () => {
                        apiService.queryCurrentContext(this.getCurrentCtxId(), this.getCurrentCtxIdType(), response => {
                            this.updateContext(response);
                            ctxObj = this.contextChildren.find(c => c.id === eid && c.typeId === '599');
                            ctxIndex = this.contextChildren.findIndex(c => c.id === eid && c.typeId === '599');
                            if (ctxObj && (ctxIndex > -1)) {
                                this.chooseContext(ctxObj, ctxIndex, () => {
                                    this.reloadSubject.next({key: 'dashboard', value: null});
                                });
                            }
                        });
                    });
                }
            }
        } else if ( fid && !eid ) {
            if (fid === this.getCurrentCtxId() && this.getCurrentCtxIdType() === '504') {
                return;
            }
            // Search for facility
            ctxObj = this.contextChildren.find(c => c.id === fid && c.typeId === '504');
            ctxIndex = this.contextChildren.findIndex(c => c.id === fid && c.typeId === '504');
            if (ctxObj && (ctxIndex > -1)) {
                this.chooseContext(ctxObj, ctxIndex, () => {
                    apiService.queryCurrentContext(this.getCurrentCtxId(), this.getCurrentCtxIdType(), response => {
                        this.updateContext(response);
                        this.reloadSubject.next({key: 'dashboard', value: null});
                    });
                });
            }
        } else {
            console.warn('DEBUG: fid(', fid, ') eid(', eid);
        }
    }

    buildGoogleTimezoneURL(lat: number, lng: number, timestamp: number, GMAP_API_KEY: string) {
        // console.log('https://maps.googleapis.com/maps/api/timezone/json?location=' + lat + ',' + lng + '&timestamp=' + Math.round(timestamp / 1000) + '&key=' + GMAP_API_KEY);
        return 'https://maps.googleapis.com/maps/api/timezone/json?location=' + lat + ',' + lng + '&timestamp=' + Math.round(timestamp / 1000) + '&key=' + GMAP_API_KEY;
    }

    getCountriesPhoneCode() {
        return {
            'af': {
                name: 'Afghanistan',
                countryCode: 'af',
                intlPrefix: '93'
            },
            'al': {
                name: 'Albania',
                countryCode: 'al',
                intlPrefix: '355'
            },
            'dz': {
                name: 'Algeria',
                countryCode: 'dz',
                intlPrefix: '213'
            },
            'as': {
                name: 'American Samoa',
                countryCode: 'as',
                intlPrefix: '1684'
            },
            'ad': {
                name: 'Andorra',
                countryCode: 'ad',
                intlPrefix: '376'
            },
            'ao': {
                name: 'Angola',
                countryCode: 'ao',
                intlPrefix: '244'
            },
            'ai': {
                name: 'Anguilla',
                countryCode: 'ai',
                intlPrefix: '1264'
            },
            'ag': {
                name: 'Antigua and Barbuda',
                countryCode: 'ag',
                intlPrefix: '1268'
            },
            'ar': {
                name: 'Argentina',
                countryCode: 'ar',
                intlPrefix: '54'
            },
            'am': {
                name: 'Armenia',
                countryCode: 'am',
                intlPrefix: '374'
            },
            'aw': {
                name: 'Aruba',
                countryCode: 'aw',
                intlPrefix: '297'
            },
            'au': {
                name: 'Australia',
                countryCode: 'au',
                intlPrefix: '61'
            },
            'at': {
                name: 'Austria',
                countryCode: 'at',
                intlPrefix: '43'
            },
            'az': {
                name: 'Azerbaijan',
                countryCode: 'az',
                intlPrefix: '994'
            },
            'bs': {
                name: 'Bahamas',
                countryCode: 'bs',
                intlPrefix: '1242'
            },
            'bh': {
                name: 'Bahrain',
                countryCode: 'bh',
                intlPrefix: '973'
            },
            'bd': {
                name: 'Bangladesh',
                countryCode: 'bd',
                intlPrefix: '880'
            },
            'bb': {
                name: 'Barbados',
                countryCode: 'bb',
                intlPrefix: '1246'
            },
            'by': {
                name: 'Belarus',
                countryCode: 'by',
                intlPrefix: '375'
            },
            'be': {
                name: 'Belgium',
                countryCode: 'be',
                intlPrefix: '32'
            },
            'bz': {
                name: 'Belize',
                countryCode: 'bz',
                intlPrefix: '501'
            },
            'bj': {
                name: 'Benin',
                countryCode: 'bj',
                intlPrefix: '229'
            },
            'bm': {
                name: 'Bermuda',
                countryCode: 'bm',
                intlPrefix: '1441'
            },
            'bt': {
                name: 'Bhutan',
                countryCode: 'bt',
                intlPrefix: '975'
            },
            'bo': {
                name: 'Bolivia',
                countryCode: 'bo',
                intlPrefix: '591'
            },
            'ba': {
                name: 'Bosnia and Herzegovina',
                countryCode: 'ba',
                intlPrefix: '387'
            },
            'bw': {
                name: 'Botswana',
                countryCode: 'bw',
                intlPrefix: '267'
            },
            'br': {
                name: 'Brazil',
                countryCode: 'br',
                intlPrefix: '55'
            },
            'io': {
                name: 'British Indian Ocean Territory',
                countryCode: 'io',
                intlPrefix: '246'
            },
            'vg': {
                name: 'British Virgin Islands',
                countryCode: 'vg',
                intlPrefix: '1284'
            },
            'bn': {
                name: 'Brunei',
                countryCode: 'bn',
                intlPrefix: '673'
            },
            'bg': {
                name: 'Bulgaria',
                countryCode: 'bg',
                intlPrefix: '359'
            },
            'bf': {
                name: 'Burkina Faso',
                countryCode: 'bf',
                intlPrefix: '226'
            },
            'bi': {
                name: 'Burundi',
                countryCode: 'bi',
                intlPrefix: '257'
            },
            'kh': {
                name: 'Cambodia',
                countryCode: 'kh',
                intlPrefix: '855'
            },
            'cm': {
                name: 'Cameroon',
                countryCode: 'cm',
                intlPrefix: '237'
            },
            'ca': {
                name: 'Canada',
                countryCode: 'ca',
                intlPrefix: '1'
            },
            'cv': {
                name: 'Cape Verde',
                countryCode: 'cv',
                intlPrefix: '238'
            },
            'bq': {
                name: 'Caribbean Netherlands',
                countryCode: 'bq',
                intlPrefix: '599'
            },
            'ky': {
                name: 'Cayman Islands',
                countryCode: 'ky',
                intlPrefix: '1345'
            },
            'cf': {
                name: 'Central African Republic',
                countryCode: 'cf',
                intlPrefix: '236'
            },
            'td': {
                name: 'Chad',
                countryCode: 'td',
                intlPrefix: '235'
            },
            'cl': {
                name: 'Chile',
                countryCode: 'cl',
                intlPrefix: '56'
            },
            'cn': {
                name: 'China',
                countryCode: 'cn',
                intlPrefix: '86'
            },
            'co': {
                name: 'Colombia',
                countryCode: 'co',
                intlPrefix: '57'
            },
            'km': {
                name: 'Comoros',
                countryCode: 'km',
                intlPrefix: '269'
            },
            'cd': {
                name: 'Congo (DRC)',
                countryCode: 'cd',
                intlPrefix: '243'
            },
            'cg': {
                name: 'Congo (Republic)',
                countryCode: 'cg',
                intlPrefix: '242'
            },
            'ck': {
                name: 'Cook Islands',
                countryCode: 'ck',
                intlPrefix: '682'
            },
            'cr': {
                name: 'Costa Rica',
                countryCode: 'cr',
                intlPrefix: '506'
            },
            'ci': {
                name: 'Côte d’Ivoire',
                countryCode: 'ci',
                intlPrefix: '225'
            },
            'hr': {
                name: 'Croatia',
                countryCode: 'hr',
                intlPrefix: '385'
            },
            'cu': {
                name: 'Cuba',
                countryCode: 'cu',
                intlPrefix: '53'
            },
            'cw': {
                name: 'Curaçao',
                countryCode: 'cw',
                intlPrefix: '599'
            },
            'cy': {
                name: 'Cyprus',
                countryCode: 'cy',
                intlPrefix: '357'
            },
            'cz': {
                name: 'Czech Republic',
                countryCode: 'cz',
                intlPrefix: '420'
            },
            'dk': {
                name: 'Denmark',
                countryCode: 'dk',
                intlPrefix: '45'
            },
            'dj': {
                name: 'Djibouti',
                countryCode: 'dj',
                intlPrefix: '253'
            },
            'dm': {
                name: 'Dominica',
                countryCode: 'dm',
                intlPrefix: '1767'
            },
            'do': {
                name: 'Dominican Republic',
                countryCode: 'do',
                intlPrefix: '1'
            },
            'ec': {
                name: 'Ecuador',
                countryCode: 'ec',
                intlPrefix: '593'
            },
            'eg': {
                name: 'Egypt',
                countryCode: 'eg',
                intlPrefix: '20'
            },
            'sv': {
                name: 'El Salvador',
                countryCode: 'sv',
                intlPrefix: '503'
            },
            'gq': {
                name: 'Equatorial Guinea',
                countryCode: 'gq',
                intlPrefix: '240'
            },
            'er': {
                name: 'Eritrea',
                countryCode: 'er',
                intlPrefix: '291'
            },
            'ee': {
                name: 'Estonia',
                countryCode: 'ee',
                intlPrefix: '372'
            },
            'et': {
                name: 'Ethiopia',
                countryCode: 'et',
                intlPrefix: '251'
            },
            'fk': {
                name: 'Falkland Islands',
                countryCode: 'fk',
                intlPrefix: '500'
            },
            'fo': {
                name: 'Faroe Islands',
                countryCode: 'fo',
                intlPrefix: '298'
            },
            'fj': {
                name: 'Fiji',
                countryCode: 'fj',
                intlPrefix: '679'
            },
            'fi': {
                name: 'Finland',
                countryCode: 'fi',
                intlPrefix: '358'
            },
            'fr': {
                name: 'France',
                countryCode: 'fr',
                intlPrefix: '33'
            },
            'gf': {
                name: 'French Guiana',
                countryCode: 'gf',
                intlPrefix: '594'
            },
            'pf': {
                name: 'French Polynesia',
                countryCode: 'pf',
                intlPrefix: '689'
            },
            'ga': {
                name: 'Gabon',
                countryCode: 'ga',
                intlPrefix: '241'
            },
            'gm': {
                name: 'Gambia',
                countryCode: 'gm',
                intlPrefix: '220'
            },
            'ge': {
                name: 'Georgia',
                countryCode: 'ge',
                intlPrefix: '995'
            },
            'de': {
                name: 'Germany',
                countryCode: 'de',
                intlPrefix: '49'
            },
            'gh': {
                name: 'Ghana',
                countryCode: 'gh',
                intlPrefix: '233'
            },
            'gi': {
                name: 'Gibraltar',
                countryCode: 'gi',
                intlPrefix: '350'
            },
            'gr': {
                name: 'Greece',
                countryCode: 'gr',
                intlPrefix: '30'
            },
            'gl': {
                name: 'Greenland',
                countryCode: 'gl',
                intlPrefix: '299'
            },
            'gd': {
                name: 'Grenada',
                countryCode: 'gd',
                intlPrefix: '1473'
            },
            'gp': {
                name: 'Guadeloupe',
                countryCode: 'gp',
                intlPrefix: '590'
            },
            'gu': {
                name: 'Guam',
                countryCode: 'gu',
                intlPrefix: '1671'
            },
            'gt': {
                name: 'Guatemala',
                countryCode: 'gt',
                intlPrefix: '502'
            },
            'gn': {
                name: 'Guinea',
                countryCode: 'gn',
                intlPrefix: '224'
            },
            'gw': {
                name: 'Guinea-Bissau',
                countryCode: 'gw',
                intlPrefix: '245'
            },
            'gy': {
                name: 'Guyana',
                countryCode: 'gy',
                intlPrefix: '592'
            },
            'ht': {
                name: 'Haiti',
                countryCode: 'ht',
                intlPrefix: '509'
            },
            'hn': {
                name: 'Honduras',
                countryCode: 'hn',
                intlPrefix: '504'
            },
            'hk': {
                name: 'Hong Kong',
                countryCode: 'hk',
                intlPrefix: '852'
            },
            'hu': {
                name: 'Hungary',
                countryCode: 'hu',
                intlPrefix: '36'
            },
            'is': {
                name: 'Iceland',
                countryCode: 'is',
                intlPrefix: '354'
            },
            'in': {
                name: 'India',
                countryCode: 'in',
                intlPrefix: '91'
            },
            'id': {
                name: 'Indonesia',
                countryCode: 'id',
                intlPrefix: '62'
            },
            'ir': {
                name: 'Iran',
                countryCode: 'ir',
                intlPrefix: '98'
            },
            'iq': {
                name: 'Iraq',
                countryCode: 'iq',
                intlPrefix: '964'
            },
            'ie': {
                name: 'Ireland',
                countryCode: 'ie',
                intlPrefix: '353'
            },
            'il': {
                name: 'Israel',
                countryCode: 'il',
                intlPrefix: '972'
            },
            'it': {
                name: 'Italy',
                countryCode: 'it',
                intlPrefix: '39'
            },
            'jm': {
                name: 'Jamaica',
                countryCode: 'jm',
                intlPrefix: '1876'
            },
            'jp': {
                name: 'Japan',
                countryCode: 'jp',
                intlPrefix: '81'
            },
            'jo': {
                name: 'Jordan',
                countryCode: 'jo',
                intlPrefix: '962'
            },
            'kz': {
                name: 'Kazakhstan',
                countryCode: 'kz',
                intlPrefix: '7'
            },
            'ke': {
                name: 'Kenya',
                countryCode: 'ke',
                intlPrefix: '254'
            },
            'ki': {
                name: 'Kiribati',
                countryCode: 'ki',
                intlPrefix: '686'
            },
            'kw': {
                name: 'Kuwait',
                countryCode: 'kw',
                intlPrefix: '965'
            },
            'kg': {
                name: 'Kyrgyzstan',
                countryCode: 'kg',
                intlPrefix: '996'
            },
            'la': {
                name: 'Laos',
                countryCode: 'la',
                intlPrefix: '856'
            },
            'lv': {
                name: 'Latvia',
                countryCode: 'lv',
                intlPrefix: '371'
            },
            'lb': {
                name: 'Lebanon',
                countryCode: 'lb',
                intlPrefix: '961'
            },
            'ls': {
                name: 'Lesotho',
                countryCode: 'ls',
                intlPrefix: '266'
            },
            'lr': {
                name: 'Liberia',
                countryCode: 'lr',
                intlPrefix: '231'
            },
            'ly': {
                name: 'Libya',
                countryCode: 'ly',
                intlPrefix: '218'
            },
            'li': {
                name: 'Liechtenstein',
                countryCode: 'li',
                intlPrefix: '423'
            },
            'lt': {
                name: 'Lithuania',
                countryCode: 'lt',
                intlPrefix: '370'
            },
            'lu': {
                name: 'Luxembourg',
                countryCode: 'lu',
                intlPrefix: '352'
            },
            'mo': {
                name: 'Macau',
                countryCode: 'mo',
                intlPrefix: '853'
            },
            'mk': {
                name: 'Macedonia',
                countryCode: 'mk',
                intlPrefix: '389'
            },
            'mg': {
                name: 'Madagascar',
                countryCode: 'mg',
                intlPrefix: '261'
            },
            'mw': {
                name: 'Malawi',
                countryCode: 'mw',
                intlPrefix: '265'
            },
            'my': {
                name: 'Malaysia',
                countryCode: 'my',
                intlPrefix: '60'
            },
            'mv': {
                name: 'Maldives',
                countryCode: 'mv',
                intlPrefix: '960'
            },
            'ml': {
                name: 'Mali',
                countryCode: 'ml',
                intlPrefix: '223'
            },
            'mt': {
                name: 'Malta',
                countryCode: 'mt',
                intlPrefix: '356'
            },
            'mh': {
                name: 'Marshall Islands',
                countryCode: 'mh',
                intlPrefix: '692'
            },
            'mq': {
                name: 'Martinique',
                countryCode: 'mq',
                intlPrefix: '596'
            },
            'mr': {
                name: 'Mauritania',
                countryCode: 'mr',
                intlPrefix: '222'
            },
            'mu': {
                name: 'Mauritius',
                countryCode: 'mu',
                intlPrefix: '230'
            },
            'mx': {
                name: 'Mexico',
                countryCode: 'mx',
                intlPrefix: '52'
            },
            'fm': {
                name: 'Micronesia',
                countryCode: 'fm',
                intlPrefix: '691'
            },
            'md': {
                name: 'Moldova',
                countryCode: 'md',
                intlPrefix: '373'
            },
            'mc': {
                name: 'Monaco',
                countryCode: 'mc',
                intlPrefix: '377'
            },
            'mn': {
                name: 'Mongolia',
                countryCode: 'mn',
                intlPrefix: '976'
            },
            'me': {
                name: 'Montenegro',
                countryCode: 'me',
                intlPrefix: '382'
            },
            'ms': {
                name: 'Montserrat',
                countryCode: 'ms',
                intlPrefix: '1664'
            },
            'ma': {
                name: 'Morocco',
                countryCode: 'ma',
                intlPrefix: '212'
            },
            'mz': {
                name: 'Mozambique',
                countryCode: 'mz',
                intlPrefix: '258'
            },
            'mm': {
                name: 'Myanmar',
                countryCode: 'mm',
                intlPrefix: '95'
            },
            'na': {
                name: 'Namibia',
                countryCode: 'na',
                intlPrefix: '264'
            },
            'nr': {
                name: 'Nauru',
                countryCode: 'nr',
                intlPrefix: '674'
            },
            'np': {
                name: 'Nepal',
                countryCode: 'np',
                intlPrefix: '977'
            },
            'nl': {
                name: 'Netherlands',
                countryCode: 'nl',
                intlPrefix: '31'
            },
            'nc': {
                name: 'New Caledonia',
                countryCode: 'nc',
                intlPrefix: '687'
            },
            'nz': {
                name: 'New Zealand',
                countryCode: 'nz',
                intlPrefix: '64'
            },
            'ni': {
                name: 'Nicaragua',
                countryCode: 'ni',
                intlPrefix: '505'
            },
            'ne': {
                name: 'Niger',
                countryCode: 'ne',
                intlPrefix: '227'
            },
            'ng': {
                name: 'Nigeria',
                countryCode: 'ng',
                intlPrefix: '234'
            },
            'nu': {
                name: 'Niue',
                countryCode: 'nu',
                intlPrefix: '683'
            },
            'nf': {
                name: 'Norfolk Island',
                countryCode: 'nf',
                intlPrefix: '672'
            },
            'kp': {
                name: 'North Korea',
                countryCode: 'kp',
                intlPrefix: '850'
            },
            'mp': {
                name: 'Northern Mariana Islands',
                countryCode: 'mp',
                intlPrefix: '1670'
            },
            'no': {
                name: 'Norway',
                countryCode: 'no',
                intlPrefix: '47'
            },
            'om': {
                name: 'Oman',
                countryCode: 'om',
                intlPrefix: '968'
            },
            'pk': {
                name: 'Pakistan',
                countryCode: 'pk',
                intlPrefix: '92'
            },
            'pw': {
                name: 'Palau',
                countryCode: 'pw',
                intlPrefix: '680'
            },
            'ps': {
                name: 'Palestine',
                countryCode: 'ps',
                intlPrefix: '970'
            },
            'pa': {
                name: 'Panama',
                countryCode: 'pa',
                intlPrefix: '507'
            },
            'pg': {
                name: 'Papua New Guinea',
                countryCode: 'pg',
                intlPrefix: '675'
            },
            'py': {
                name: 'Paraguay',
                countryCode: 'py',
                intlPrefix: '595'
            },
            'pe': {
                name: 'Peru',
                countryCode: 'pe',
                intlPrefix: '51'
            },
            'ph': {
                name: 'Philippines',
                countryCode: 'ph',
                intlPrefix: '63'
            },
            'pl': {
                name: 'Poland',
                countryCode: 'pl',
                intlPrefix: '48'
            },
            'pt': {
                name: 'Portugal',
                countryCode: 'pt',
                intlPrefix: '351'
            },
            'pr': {
                name: 'Puerto Rico',
                countryCode: 'pr',
                intlPrefix: '1'
            },
            'qa': {
                name: 'Qatar',
                countryCode: 'qa',
                intlPrefix: '974'
            },
            're': {
                name: 'Réunion',
                countryCode: 're',
                intlPrefix: '262'
            },
            'ro': {
                name: 'Romania',
                countryCode: 'ro',
                intlPrefix: '40'
            },
            'ru': {
                name: 'Russia',
                countryCode: 'ru',
                intlPrefix: '7'
            },
            'rw': {
                name: 'Rwanda',
                countryCode: 'rw',
                intlPrefix: '250'
            },
            'bl': {
                name: 'Saint Barthélemy',
                countryCode: 'bl',
                intlPrefix: '590'
            },
            'sh': {
                name: 'Saint Helena',
                countryCode: 'sh',
                intlPrefix: '290'
            },
            'kn': {
                name: 'Saint Kitts and Nevis',
                countryCode: 'kn',
                intlPrefix: '1869'
            },
            'lc': {
                name: 'Saint Lucia',
                countryCode: 'lc',
                intlPrefix: '1758'
            },
            'mf': {
                name: 'Saint Martin',
                countryCode: 'mf',
                intlPrefix: '590'
            },
            'pm': {
                name: 'Saint Pierre and Miquelon',
                countryCode: 'pm',
                intlPrefix: '508'
            },
            'vc': {
                name: 'Saint Vincent and the Grenadines',
                countryCode: 'vc',
                intlPrefix: '1784'
            },
            'ws': {
                name: 'Samoa',
                countryCode: 'ws',
                intlPrefix: '685'
            },
            'sm': {
                name: 'San Marino',
                countryCode: 'sm',
                intlPrefix: '378'
            },
            'st': {
                name: 'São Tomé and Príncipe',
                countryCode: 'st',
                intlPrefix: '239'
            },
            'sa': {
                name: 'Saudi Arabia',
                countryCode: 'sa',
                intlPrefix: '966'
            },
            'sn': {
                name: 'Senegal',
                countryCode: 'sn',
                intlPrefix: '221'
            },
            'rs': {
                name: 'Serbia',
                countryCode: 'rs',
                intlPrefix: '381'
            },
            'sc': {
                name: 'Seychelles',
                countryCode: 'sc',
                intlPrefix: '248'
            },
            'sl': {
                name: 'Sierra Leone',
                countryCode: 'sl',
                intlPrefix: '232'
            },
            'sg': {
                name: 'Singapore',
                countryCode: 'sg',
                intlPrefix: '65'
            },
            'sx': {
                name: 'Sint Maarten',
                countryCode: 'sx',
                intlPrefix: '1721'
            },
            'sk': {
                name: 'Slovakia',
                countryCode: 'sk',
                intlPrefix: '421'
            },
            'si': {
                name: 'Slovenia',
                countryCode: 'si',
                intlPrefix: '386'
            },
            'sb': {
                name: 'Solomon Islands',
                countryCode: 'sb',
                intlPrefix: '677'
            },
            'so': {
                name: 'Somalia',
                countryCode: 'so',
                intlPrefix: '252'
            },
            'za': {
                name: 'South Africa',
                countryCode: 'za',
                intlPrefix: '27'
            },
            'kr': {
                name: 'South Korea',
                countryCode: 'kr',
                intlPrefix: '82'
            },
            'ss': {
                name: 'South Sudan',
                countryCode: 'ss',
                intlPrefix: '211'
            },
            'es': {
                name: 'Spain',
                countryCode: 'es',
                intlPrefix: '34'
            },
            'lk': {
                name: 'Sri Lanka',
                countryCode: 'lk',
                intlPrefix: '94'
            },
            'sd': {
                name: 'Sudan',
                countryCode: 'sd',
                intlPrefix: '249'
            },
            'sr': {
                name: 'Suriname',
                countryCode: 'sr',
                intlPrefix: '597'
            },
            'sz': {
                name: 'Swaziland',
                countryCode: 'sz',
                intlPrefix: '268'
            },
            'se': {
                name: 'Sweden',
                countryCode: 'se',
                intlPrefix: '46'
            },
            'ch': {
                name: 'Switzerland',
                countryCode: 'ch',
                intlPrefix: '41'
            },
            'sy': {
                name: 'Syria',
                countryCode: 'sy',
                intlPrefix: '963'
            },
            'tw': {
                name: 'Taiwan',
                countryCode: 'tw',
                intlPrefix: '886'
            },
            'tj': {
                name: 'Tajikistan',
                countryCode: 'tj',
                intlPrefix: '992'
            },
            'tz': {
                name: 'Tanzania',
                countryCode: 'tz',
                intlPrefix: '255'
            },
            'th': {
                name: 'Thailand',
                countryCode: 'th',
                intlPrefix: '66'
            },
            'tl': {
                name: 'Timor-Leste',
                countryCode: 'tl',
                intlPrefix: '670'
            },
            'tg': {
                name: 'Togo',
                countryCode: 'tg',
                intlPrefix: '228'
            },
            'tk': {
                name: 'Tokelau',
                countryCode: 'tk',
                intlPrefix: '690'
            },
            'to': {
                name: 'Tonga',
                countryCode: 'to',
                intlPrefix: '676'
            },
            'tt': {
                name: 'Trinidad and Tobago',
                countryCode: 'tt',
                intlPrefix: '1868'
            },
            'tn': {
                name: 'Tunisia',
                countryCode: 'tn',
                intlPrefix: '216'
            },
            'tr': {
                name: 'Turkey',
                countryCode: 'tr',
                intlPrefix: '90'
            },
            'tm': {
                name: 'Turkmenistan',
                countryCode: 'tm',
                intlPrefix: '993'
            },
            'tc': {
                name: 'Turks and Caicos Islands',
                countryCode: 'tc',
                intlPrefix: '1649'
            },
            'tv': {
                name: 'Tuvalu',
                countryCode: 'tv',
                intlPrefix: '688'
            },
            'vi': {
                name: 'U.S. Virgin Islands',
                countryCode: 'vi',
                intlPrefix: '1340'
            },
            'ug': {
                name: 'Uganda',
                countryCode: 'ug',
                intlPrefix: '256'
            },
            'ua': {
                name: 'Ukraine (Україна)',
                countryCode: 'ua',
                intlPrefix: '380'
            },
            'ae': {
                name: 'United Arab Emirates',
                countryCode: 'ae',
                intlPrefix: '971'
            },
            'gb': {
                name: 'United Kingdom',
                countryCode: 'gb',
                intlPrefix: '44'
            },
            'us': {
                name: 'United States',
                countryCode: 'us',
                intlPrefix: '1'
            },
            'uy': {
                name: 'Uruguay',
                countryCode: 'uy',
                intlPrefix: '598'
            },
            'uz': {
                name: 'Uzbekistan',
                countryCode: 'uz',
                intlPrefix: '998'
            },
            'vu': {
                name: 'Vanuatu',
                countryCode: 'vu',
                intlPrefix: '678'
            },
            'va': {
                name: 'Vatican City',
                countryCode: 'va',
                intlPrefix: '39'
            },
            've': {
                name: 'Venezuela',
                countryCode: 've',
                intlPrefix: '58'
            },
            'vn': {
                name: 'Vietnam',
                countryCode: 'vn',
                intlPrefix: '84'
            },
            'wf': {
                name: 'Wallis and Futuna',
                countryCode: 'wf',
                intlPrefix: '681'
            },
            'ye': {
                name: 'Yemen',
                countryCode: 'ye',
                intlPrefix: '967'
            },
            'zm': {
                name: 'Zambia',
                countryCode: 'zm',
                intlPrefix: '260'
            },
            'zw': {
                name: 'Zimbabwe',
                countryCode: 'zw',
                intlPrefix: '263'
            }
          };
    }


    showToastCustomMessage = async(message) => {
        console.log('showToastMessage');

        switch(message) {
            case 'Invalid row number (65536) outside allowable range (0..65535)':
                message = 'Excel Generator Limited to 65535 Rows. Please use a smaller time frame';
                break;
            case 'Statement cancelled due to timeout or client request':
                message = 'Request Size Too Large. Please use a smaller time frame';
                break;
            default:
                break;
        }


        const toast = await toastController.create({
            message: message,
            duration: 15000
          });
          await toast.present();
    }

    showToastDefaultMessage = async() => {
        console.log('showToastMessage');
        const toast = await toastController.create({
            message: 'There was a problem, try later.',
            duration: 6000
          });
          await toast.present();
    }
}
